<div class="wrap">

  <div class="login-modal">

    <img src="../assets/img/logo_footer.svg"
         class="logo"
         alt="Nexttech"
    />

    <form (ngSubmit)="loginUser()">
      <div class="flex-item">
        <mat-form-field>
          <input matInput placeholder="USERNAME" [formControl]="email" type="email" class="sign-in-text">
          <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>
      </div>
      <div class="flex-item">
        <mat-form-field>
          <input matInput placeholder="PASSWORD" [formControl]="password" [type]="hide ? 'password' : 'text'"
                 class="sign-in-text">
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
          <mat-error *ngIf="password.invalid">You must enter a password</mat-error>
        </mat-form-field>
      </div>
      <div class="flex-item">
        <div class="form-actions">
          <button mat-button color="primary">Login</button>
        </div>
      </div>
    </form>
  </div>
</div>
