<app-login *ngIf="!isLoggedIn()"></app-login>
<app-reset *ngIf="shouldResetPassword()"></app-reset>

<mat-sidenav-container *ngIf="isLoggedIn() && !shouldResetPassword()" class="all-wrap">
  <mat-sidenav #sidenav mode="over">
    <mat-nav-list class="side-nav-list">
      <a routerLink="/dashboard" mat-list-item>Dashboard</a>
      <a *ngIf="auth.isAdmin()" routerLink="/users" mat-list-item>Users</a>
      <a mat-list-item (click)="logout()">Sign Out</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="page-wrap">

    <mat-toolbar class="header">
      <img
        src="../assets/img/logo_header.svg"
        alt="nexttech parking" class="logo-std">

      <span class="toolbar-spacer"></span>

      <!--
        Only show the navbar routes if the user is an admin,
        the dashboard link is useless for non-admin users,
        as the dashboard is literally the only route of this application.
        as the dashboard is literally the only route of this application.
      -->
      <ng-container *ngIf="auth.isAdmin() else simpleNav">
        <div class="nav" ngClass.lt-sm="hidden">
          <a class="router-link" routerLink="/dashboard" routerLinkActive="active-link">Dashboard</a>
          <a class="router-link" routerLink="/users" routerLinkActive="active-link">Users</a>
          <a class="router-link" (click)="logout()">Sign out</a>
        </div>
        <mat-icon ngClass.gt-xs="hidden" (click)="sidenav.toggle()">{{ sidenav.opened ? 'menu_open' : 'menu' }}</mat-icon>
      </ng-container>
      <ng-template #simpleNav>
        <div class="nav">
          <a class="router-link" (click)="logout()">Sign out</a>
        </div>
      </ng-template>


    </mat-toolbar>

    <main class="content">
      <router-outlet></router-outlet>
    </main>

    <!-- sticky footer -->
    <footer>
      <img
        src="../assets/img/logo_footer.svg"
        alt="Nexttech" class="logo-footer">

      <span class="copyright">Copyright {{ currentYear }} Nexttech. All Rights Reserved.</span>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
